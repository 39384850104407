<template>
  <div class="screenLogin">
    <div class="containLogin">
      <div id="space" class="spaceDiv"></div>
      <div id="login">
        <div class="text-center mb-5">
          <img
            class="logo"
            src="../../assets/logo-deitada.png"
            alt="appdaturma-login"
          />
        </div>
        <div class="cardLogin">
          <div class="formulario">
            <div class="tituloCard">
              Bem vindo
            </div>
            <div>
              <div>
                <div>
                  <div class="labelInput">Email</div>
                  <input
                    name="email"
                    type="text"
                    :value="email"
                    @input="event => (email = event.target.value)"
                    class="inputCustom"
                    :class="{ errorInput: errorEmail }"
                  />
                </div>
                <div>
                  <div class="labelInput">Senha</div>
                  <div v-show="showPassword" class="inputIcon">
                    <input
                      name="password"
                      :value="password"
                      @input="event => (password = event.target.value)"
                      type="text"
                      class="inputCustom"
                      :class="{ errorInput: errorPassword }"
                    />
                    <div class="iconAbsolute">
                      <v-icon @click="showPassword = !showPassword"
                        >mdi-eye-off</v-icon
                      >
                    </div>
                  </div>
                  <div v-show="!showPassword" class="inputIcon">
                    <input
                      :value="password"
                      @input="event => (password = event.target.value)"
                      type="password"
                      class="inputCustom"
                      autocomplete="off"
                    />
                    <div class="iconAbsolute">
                      <v-icon @click="showPassword = !showPassword"
                        >mdi-eye</v-icon
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="esqueciMinhaSenha">
                <a
                  class="linkEsqueciMinhaSenha"
                  id="btnEsqueceuSenha"
                  @click="routerRedirectRecoveryPass()"
                  >Esqueci minha senha
                </a>
              </div>
              <div class="actions">
                <button
                  class="btnCustomLogin"
                  :disabled="loading"
                  @click="processLogin()"
                >
                  <span v-show="!loading">Entrar</span>
                  <span v-show="loading">
                    <v-icon
                      size="25"
                      class="animate-spin"
                      color="rgb(150,150,150)"
                    >
                      mdi-loading
                    </v-icon>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="space" class="spaceDiv"></div>
    </div>
    <div class="containHero">
      <img class="animationImage" src="../../assets/login-animation.svg" />
    </div>
  </div>
</template>

<script>
import userAuth from "@/middleware/userAuth.js";
import userController from "@/middleware/userController.js";

export default {
  data() {
    return {
      showTemp: true,
      email: "",
      password: "",
      showPassword: false,
      valid: false,
      modalSenhaPerdida: false,
      loading: false,
      errorEmail: false,
      errorPassword: false
    };
  },

  computed: {
    computedTypeInput() {
      if (this.showPassword) {
        return "text";
      } else {
        return "password";
      }
    }
  },
  methods: {
    routerRedirectRecoveryPass() {
      this.$router.push("/esqueci-minha-senha");
    },
    validForm() {
      let email = this.email.toLowerCase();
      this.email = email;
      if (
        !this.email ||
        this.email.length == 0 ||
        !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
      ) {
        this.$root.errorModal("Verifique o campo email está inválido");
        this.errorEmail = true;
        return false;
      }

      if (this.password.length == 0 || this.password.length < 3) {
        this.$root.errorModal("Verifique o campo de senha está inválida");
        this.errorPassword = true;
        return false;
      }

      return true;
    },
    verificaTipoDeUsuario() {
      userController.getUserData().then(response => {
        if (response.data.role == "PARTICIPANTE") {
          this.$router.push("/aluno/dashboard");
        } else {
          this.$root.errorModal(
            "Acesso negado, você não é participante, crie uma conta com outro email para poder acessar."
          );
        }
      });
    },
    requestLogin() {
      let userEmail = this.email;
      let userPass = this.password;

      userAuth
        .login(userEmail, userPass)
        .then(response => {
          let token = response.data;
          this.$store.commit("aluno/setTokenAuth", token);
          localStorage.setItem("JWT_APPDATURMA_ALUNO/token", token.accessToken);
          localStorage.setItem("JWT_APPDATURMA_ALUNO/type", token.tokenType);
        })
        .then(() => {
          this.verificaTipoDeUsuario();
        })
        .finally(() => {
          this.loading = false;
        })
        .catch(err => {
          console.error(err);
          if (err.status == 405) {
            const codigo = err.data.code;
            this.$router.push("/aluno/completar-cadastro/" + codigo);
          } else {
            this.$root.errorModal(err.data.message);
          }
        });
    },
    removeKeysLocalStorage() {
      localStorage.removeItem("JWT_APPDATURMA_ALUNO/token");
      localStorage.removeItem("JWT_APPDATURMA_ALUNO/type");
    },
    processLogin() {
      this.errorEmail = false;
      this.errorPassword = false;
      this.email = this.email.toLowerCase();
      const validEmail = this.validForm();

      if (!validEmail) {
        return false;
      }

      this.loading = true;
      this.requestLogin();
    }
  }
};
</script>

<style lang="scss" scoped>
.inputIcon {
  position: relative;
}
.iconAbsolute {
  position: absolute;
  right: 10px;
  top: 7px;
}
.labelInput {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 4px;
}
.inputCustom {
  border-radius: 4px;
  background-color: white;
  color: rgb(50, 50, 50, 1);
  font-size: 14px;
  border: 1px solid rgba(50, 50, 50, 0.1);
  padding: 8px;
  width: 100%;
  margin-bottom: 10px;
  &.errorInput {
    outline: 2px solid rgb(200, 0, 0, 0.4);
  }
}
.btnCustomLogin {
  padding: 8px 16px;
  border-radius: 6px;
  background-color: rgba(17, 27, 121, 1);
  color: white;
  width: 80%;
  margin: 0 auto;
  display: inline-block;
  &:disabled {
    background-color: #f2f2f2;
    color: rgb(110, 110, 150);
    cursor: not-allowed;
  }
}
.tituloCard {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  color: rgb(50, 50, 50, 1);
}
.spaceDiv {
  display: block;
  @media screen and (max-width: 768px) {
    //display: block;
  }
}
.cardLogin {
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  margin-bottom: 10px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(200, 200, 200, 0.3);
  width: 300px;
  min-width: 300px;
}
.logo {
  width: 200px;
}
.screenLogin {
  background-color: white;
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.containHero {
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.animationImage {
  max-width: 100vw;
  max-height: 100vh;
  margin: 0 auto;
  flex: 1;
}
.containLogin {
  background-color: rgba(150, 150, 150, 0.1);
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  height: 100vh;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.actions {
  padding: 20px 0px;
  text-align: center;
}

.esqueciMinhaSenha {
  text-align: right;
  .linkEsqueciMinhaSenha {
    font-size: 14px;
    color: rgba(50, 50, 50, 1);
    &:hover {
      text-decoration: underline;
    }
  }
}

.newDesign {
  min-height: 90vh;
  background-color: #f5f6f8;
  .loginImagem {
    max-width: 600px;
    width: 90%;
    object-position: center;
  }
  &.mobile {
    background-image: url("../../assets/dash/login-fundo-imagem.svg");
  }
}
</style>
